<template>
    <div class="record" v-show="prizeShow">
        <div class="bg" @click="prizeShow=false"></div>
        <div class="lottery-box">
            <div class="end" @click="hide">×</div>
            <!-- <div class="top">
              <img :src="headimgurl" alt="" v-show="headimgurl">
              <p>{{ nickname }}</p>
            </div> -->
            <div class="content">
                <div class="box" v-if="showErrBox">
                  <div class="qc-code">
                    <img :src="showErrData.service_qrcode" height="100" width="100"/>
                  </div>
                  <div style="text-align: center;margin-bottom: 20px">{{ showErrData.massage }}</div>
                </div>
                <div class="prize_no" v-if="type === 0">
                  谢谢参与
                </div>
                
                <!-- 客服二维码 -->
                <div class="box" v-if="type === 1">
                  <div class="qc-code">
                    <img :src="qrCode" height="100" width="100"/>
                  </div>
                  <div style="text-align: center;margin-bottom: 20px">
                    <div style="color: #e3453a">{{receiveName}}</div>
                    <!-- <div>联系客服领取</div> -->
                  </div>
                  <div style="margin-bottom: 25px; text-align: center;">{{ defaultData }}</div>
                </div>

                <!-- 兑换码 -->
                <div class="box" v-if="type === 2">
                  <div style="margin-bottom: 20px; ">
                    <div style="color: #e3453a; text-align: center; font-size: 18px; margin-bottom: 20px">{{receiveName}}</div>
                    <div>
                      兑换码：<span>{{ redeemCode }}sadasdsad</span>
                    </div>
                  </div>
                  <div style="margin-bottom: 25px;">{{ defaultData }}</div>
                </div>

                <!-- 红包 -->
                <div class="box"  v-if="type === 3">
                  <div  class="prize_no" style='margin:15px 0 20px'>
                    恭喜获得红包！
                  </div>
                  <div class="exchange_code">
                      <span style="color: #e3453a;">{{receiveName}}</span>
                  </div>
                  <div style="margin-bottom: 25px; text-align: center;">{{ defaultData }}</div>
                </div>

                <!-- 实物 -->
                <div class="box"  v-if="type === 4" style="width: 88vw">
                  <div style="color: #e3453a;margin-bottom: 10px; font-size: 18px">{{receiveName}}</div>
                  <p>请填写收货地址</p>
                  <div class="sendAddress">
                      <Field v-model="sendForm.recipient" label="收货人" placeholder="请输入收货人姓名" />
                      <Field v-model="sendForm.phone" label="手机号" placeholder="请输入手机号" maxlength="11" />
                      <Field
                        v-model="sendArea"
                        readonly
                        label="地区"
                        placeholder="请选择所在地区"
                        @click="showArea = true"
                      />
                      <Field v-model="sendForm.detailAddress" label="详细地址" placeholder="请输入详细地址" />
                      <div style="padding: 20px 15px 0">
                        <Button round block  color="linear-gradient(to right, #ff6034, #ee0a24)" @click="receiveConfirm">领取奖品</Button>
                      </div>
                  </div>
                </div>
                
                <!-- <div class="prize_no" v-if="controlStyle" style='margin:15px 0 40px'>{{receiveName}}</div> -->
                <!-- <div class="box" v-else>

                    <div  class="prize_no" v-if="redeemCode == '' && qrCode == ''" style='margin:15px 0 40px'>
                      恭喜获得红包！
                    </div>

                    <div v-else-if="redeemCode=='' && qrCode" class="qc-code">
                        <img :src="qrCode" height="100" width="100"/>
                    </div>
                    <div class="exchange_code">
                      <span style="color: #e3453a;margin-right: 5px;">{{receiveName}}</span>
                      <div v-if="redeemCode!=''">兑换码：<span>{{ redeemCode }}</span></div></div>
                    <div style="margin-bottom: 25px;">{{ defaultData }}</div>
                </div> -->
                
            </div>

        </div>
        <Popup v-model="showArea" round position="bottom">
          <Area title="标题" :area-list="areaList" @confirm="showAreaHanl" @cancel="showArea = false"/>
        </Popup>
    </div>
</template>
<script>
import { Field, Area, Popup, Button, Toast } from 'vant';
import { areaList } from '@vant/area-data';
import { recipientSubmit } from "@/api/lottery";
export default {
  components: {
    Field,
    Area,
    Popup,
    Button
  },
    data() {
        return {
          areaList,
          showArea: false,
          prizeShow: false,
          qrCode:'',
          redeemCode:'',
          receiveName:'',
          headimgurl:'',
          nickname:'',
          controlStyle:false,
          defaultData: '',
          type: '',
          sendForm: {

          },
          sendArea: '',
          id: '',
          showErrBox: false,
          showErrData: {}
        }
    },
  methods: {
        show(data) {
          console.log(data)
          this.defaultData = data.description
          this.prizeShow = true
          this.receiveName=data.receiveName
          this.qrCode=data.receiveQr
          this.redeemCode=data.receiveCode
          this.nickname=data.nickname
          this.headimgurl=data.headimgurl
          this.type = data.type
          this.id = data.id
          // if(this.receiveName=='谢谢参与'){
          //   this.controlStyle=true
          // }else{
          //   this.controlStyle=false
          // }
        },
        showErr (data) {
          this.showErrData = data
          this.showErrBox = true
          this.prizeShow = true
        },
        showReceive (data) {
          this.defaultData = data.description
          this.prizeShow = true
          this.receiveName=data.prizeName
          this.qrCode=data.receiveQr
          this.redeemCode=data.receiveCode
          this.nickname=data.nickname
          this.headimgurl=data.headimgurl
          this.type = data.receiveType
          this.id = data.id
        },
        hide() {
            this.prizeShow = false
            this.sendForm = {}
            this.showErrData = {}
            this.showErrBox = false
        },
        showAreaHanl (data) {
          console.log(data)
          this.sendArea = data[0].name + data[1].name + data[2].name
          this.showArea = false
        },
        receiveConfirm () {
          if (!this.sendForm.recipient || !this.sendForm.phone || this.sendForm.phone.length != 11 || !this.sendForm.detailAddress || !this.sendArea) {
            Toast.fail('请完整填写收货信息')
            return
          }
          this.sendForm.address = this.sendArea + this.sendForm.detailAddress
          this.sendForm.id = this.id
          recipientSubmit(this.sendForm).then(res => {
            Toast.success('领取成功！')
            this.prizeShow = false
            this.$parent.getClientData()
          })
        }
          
    }
}
</script>

<style scoped lang="less">
.record {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 100;

    .sendAddress {
      margin-bottom: 30px;
      width: 95%;
    }

    .bg {
        width: 100vw;
        height: 100vh;
        background-color: #00000094;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
    }

    .lottery-box {
        width: 88vw;
        min-height: 100px;
        background-color: #ffffff;
        border-radius: 18px;
        // margin-top: -1020px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        position: relative;
        z-index: 20;

        .top {
            width: 78vw;
            display: flex;
            align-items: center;
            height: 30px;
            margin-top: 32px;
            img{
              width: 33px;
              height: 33px;
            }
            p {
                color: #e3453a;
                margin-left: 14px;
                font-size: 18px;
                font-weight: bold;
                margin-top: 17px;
            }
            span {
                display: block;
                margin-top: -16px;
            }
        }
        .box {
            width: 78vw;
            min-height: 100px;
            padding-top: 20px;
            display: flex;
            align-items: center;
            flex-direction: column;
            .qc-code {
                width: 70vw;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 120px;
                margin-bottom: 10px;
            }

        }

    }
}
.end {
    position: absolute;
    font-size: 24px;
    right: 12px;
}
.exchange_code{
  margin-bottom: 20px;
  display: flex;
  span{
    font-weight: bold;
    font-size: 15px;
  }
}
.prize_no{
  // font-weight: bold;
  font-size: 20px;
  margin-top: 30px;
}

</style>
