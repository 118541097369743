<template>
    <div class="page">
        <!-- <div class="title-box" v-if="corp.name || corp.logo || corp.description">
            <div class="title-content">
                <div class="logo">
                    <img :src="corp.logo" height="40" width="40" alt="" v-if="corp.logo!=''"/>
                </div>
                <div class="company-name">
                    <div class="title">{{ corp.name }}</div>
                    <span class="synopsis">{{ corp.description }}</span>
                </div>
            </div>
        </div> -->
        <div class="explain">
            <span @click="$refs.explain.show(clientData.lottery)">活动说明</span>
        </div>
        <div class="lottery-draw">
            <img v-if="styleSetting.bg_url" :src="styleSetting.bg_url" alt="" style="width: 100%">
            <div v-else style="height: 100vw"></div>
            <div class="lottery-box" :style="{'top': 'calc(100vh * ' + styleSetting.lottery_top/450 + ')'}">
                <!-- <div class="top">
                    <div class="logo"><img :src="weChatUserNews.headimgurl" height="32" width="32"/></div>
                    {{ weChatUserNews.nickname }}
                </div> -->
                <!-- <div class="bottom">
                    <div class="box">
                        
                    </div>
                </div> -->
                <LuckyWheel
                    v-if="type == 'roulette' && showLottery"
                    style="width: 80vw; height: 80vw"
                    ref="LuckyWheel"
                    :prizes="luckDraw.prizes"
                    :defaultConfig="luckDraw.defaultConfig"
                    :blocks="luckDraw.blocks"
                    :buttons="luckDraw.buttons"
                    @start="startCallBack"
                />
                <LuckyGrid
                    v-if="type == 'rotate' && showLottery"
                    style="width: 80vw; height: 80vw"
                    :prizes="luckyGrid.prizes"
                    ref="LuckyWheel"
                    :blocks="luckyGrid.blocks"
                    :buttons="luckyGrid.buttons"
                    :defaultConfig="luckyGrid.defaultConfig"
                    :active-style="luckyGrid.activeStyle"
                    @start="startCallBack"
                    />
                <!-- <div class="prize_name" v-if=" clientData.message !== undefined &&clientData.message.prize_name">
                    <img :src="clientData.message.avatar" alt="">
                    恭喜<span>{{ clientData.message.nickname }}</span>获得{{ clientData.message.prize_name }}
                </div> -->
            </div>
        </div>
        <div class="record" v-if="showLottery">
            <div class="lottery-box">
                <div class="top">
                    <p>中奖记录</p>
                    <!-- <span>记得及时兑奖哦~</span> -->
                </div>
                <div class="bottom">
                    <div class="box" v-if="clientData.win_list && clientData.win_list.length">
                        <div class="draw_list" v-for="(item,index) in clientData.win_list" :key="index">
                            <div>{{ item.createdAt }}</div>
                            <div class="exchange">
                                <div class="exchange-box">
                                    <div class="exchange-name">
                                        <img :src="item.prizeImage || item.receiveQr" alt="" style="width: 35px;height: 35px;">
                                        <span>{{ item.prizeName }}</span>
                                    </div>
                                    <div class="cash-prize">
                                        <a-button type="danger" @click="receiveReward(item)"
                                                  v-if="item.receiveStatus==0">兑奖
                                        </a-button>
                                        <a-button class="converted" @click="receiveReward(item)" v-else>已兑换</a-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box" v-else>
                        <div class="no_draw_list">暂无抽奖记录</div>
                    </div>
                </div>
<!--                <div>注：每次抽奖需要消耗<span></span>积分</div>-->
            </div>
            <explain ref="explain"/>
            <prize ref="prize"/>
        </div>
    </div>
</template>

<script>
    import {contactDataApi, openUserInfoApi, contactLotteryApi, receiveApi} from "@/api/lottery";
    import storage from 'store'
    import explain from "@/views/lottery/explain";
    import prize from "@/views/lottery/prize";
    import { getSdkConfig } from '@/api/common'

    export default {
        components: {
            explain,
            prize
        },
        data() {
            return {
                type: '',
                url: window.location.href,
                luckyGrid: {
                    prizes: [
                        { x: 0, y: 0 },
                        { x: 1, y: 0 },
                        { x: 2, y: 0 },
                        { x: 2, y: 1 },
                        { x: 2, y: 2 },
                        { x: 1, y: 2 },
                        { x: 0, y: 2 },
                        { x: 0, y: 1 },
                    ],
                    defaultConfig: {
                        gutter: 10
                    },
                    blocks: [
                        { padding: '12px', background: '#fff', borderRadius: '10px' },
                        { padding: '14px', background: '#fff', borderRadius: '10px' },
                    ],
                    buttons: [
                        {
                            x: 1, y: 1,
                            background: '#ff5636',
                            padding: '10px',
                            borderRadius: '10px',
                            fonts: [{ text: '开始抽奖', top: '15%', fontSize: '16px', lengthLimit: '70%', fontColor: '#fff' }],
                        },
                    ],
                    defaultStyle: {
                        borderRadius: '10px'
                    },
                    activeStyle: {
                        background: '#595959'
                    }
                },
                //  抽奖
                luckDraw: {
                    //轮盘数据
                    prizes: [],
                    defaultConfig: {//默认样式
                        gutter: '5px',//扇形之间的缝隙（默认等于 0无间隙）
                        offsetDegree: 45
                    },
                    blocks: [//背景样式修改
                        {
                            padding: '17px',
                            imgs: [
                                {
                                    src: require("../../static/images/001.png"), // 转盘底图
                                    width: "100%",
                                    rotate: true
                                }
                            ]
                        },
                        {padding: '24px', background: '#F9D201'}
                    ],
                    buttons: [
                        {radius: '28px', background: '#FF5636', pointer: true},
                        {radius: '35px', background: '#FEC739'},
                        {
                            radius: '30px', background: '#FF5636',
                            fonts: [
                                {
                                    text: '开始\n抽奖',
                                    fontSize: '15px',
                                    top: -18,
                                    fontColor: "#fff",
                                }
                            ]
                        }
                    ]
                },
                //  用户微信信息
                weChatUserNews: {},
                //  客户数据
                clientData: {},
                corp: {},
                //  设置的奖品
                prizeSetNews: [],
                showLottery: false,
                styleSetting: {
                    lottery_top: 20
                }
            }
        },
        created() {
            //  获取参数
            this.id = this.$route.query.id;
            this.source = this.$route.query.source;
            this.getOpenUserInfo();
        },
        mounted () {
            // setTimeout(() => {
            //     console.log('ok')
            //     this.$refs.prize.show({
            //         description: "红包99",
            //         headimgurl: "https://thirdwx.qlogo.cn/mmopen/vi_32/CSSlcbKpCu3qLENX5X7ufBqxVXtPWHObIFzdCYmUZcHE5ueNXoP4zeWgicIms4ztLZjLrHPuE6l6qwFCffdlSiag/132",
            //         nickname: "KEEN",
            //         receiveCode: "",
            //         receiveName: "红包99元",
            //         receiveQr: "https://thirdwx.qlogo.cn/mmopen/vi_32/CSSlcbKpCu3qLENX5X7ufBqxVXtPWHObIFzdCYmUZcHE5ueNXoP4zeWgicIms4ztLZjLrHPuE6l6qwFCffdlSiag/132",
            //         type: 3
            //     })
            // }, 2000)
           
        },
        methods: {
            getOpenUserInfo() {
                let that = this;
                openUserInfoApi({
                    id: that.id
                }).then((res) => {
                    if (res.data.openid === undefined) {
                        let redirectUrl = '/auth/lottery?id='+that.id+'&target=' + encodeURIComponent(that.url);
                        console.log(that)
                        that.$redirectAuth(redirectUrl);
                    }
                    this.weChatUserNews = res.data;
                    storage.set('ACCESS_TOKEN', 'Bearer ' + res.data.token)
                    this.getClientData();
                });
            },
            //  领取奖励
            receiveReward(item) {
                if (item.receiveStatus == 0 && item.receiveType != 4) {
                    receiveApi({
                        id: item.id
                    }).then((res) => {
                        this.$message.success('奖励领取成功')
                        this.getClientData()
                    })
                }
                this.$refs.prize.showReceive(item)
            },
            //  获取客户数据
            getClientData() {
                let params = {
                    id: this.id,
                    union_id: this.weChatUserNews.unionid,
                    nickname: this.weChatUserNews.nickname,
                    avatar: this.weChatUserNews.headimgurl,
                    city: this.weChatUserNews.city,
                    source: this.source
                }
                contactDataApi(params).then((res) => {
                    document.title = res.data.lottery.name
                    this.clientData = res.data
                    
                    res.data.prize.prizeSet.forEach((item,index)=>{
                      this.clientData.win_list.forEach((obj,idx)=>{
                        if(item.name==obj.prizeName){
                          if(item.type==2){
                            obj.receiveQr=item.image
                          }
                        }
                      })
                    })
                    this.type = res.data.lottery.type
                    this.corp = res.data.corp
                    
                    // 设置抽奖组件样式
                    if (res.data.lottery.styleSetting) {
                        this.styleSetting = JSON.parse(res.data.lottery.styleSetting)
                        console.log(this.styleSetting)
                    }
                    
                    switch (this.type) {
                        case 'rotate':
                            this.luckyGrid.activeStyle.fontColor = this.styleSetting.active_fontColor
                            this.luckyGrid.activeStyle.background = this.styleSetting.active_bgColor
                            this.luckyGrid.blocks[1].background = this.styleSetting.lottery_bgColor
                            this.luckyGrid.blocks[0].background = this.styleSetting.lottery_borderColor
                            if (this.styleSetting.startBtn_url) {
                                this.luckyGrid.buttons[0] = {
                                    x: 1, y: 1,
                                    borderRadius: '0px',
                                    imgs: [
                                        {
                                            src: this.styleSetting.startBtn_url,
                                            width: "100%",
                                            rotate: true
                                        }
                                    ]
                                }
                            }
                            break
                        case 'roulette':
                            break
                    }
                    console.log(this.styleSetting)
                    this.showLottery = true

                    //  处理奖品设置数据
                    this.prizeSetNews = this.clientData.prize.prizeSet
                    this.handSetprize(this.prizeSetNews)

                    // this.$refs.LuckyWheel.init()

                    // 设置分享
                    //设置分享
                    getSdkConfig({
                        url: window.location.href,
                        apiList: ['openLocation', 'updateAppMessageShareData', 'updateTimelineShareData','onMenuShareAppMessage', 'onMenuShareTimeline'],
                    }).then(sdk => {
                        this.$wx.init(sdk.data, () => {
                            this.$wx.share({
                                title: res.data.lottery.shareTitle,
                                desc: res.data.lottery.shareDesc,
                                imgUrl: res.data.lottery.shareImage,
                                link: window.location.href
                            })
                        })
                    })
                })
            },
            shuffle(arr) {
                var length = arr.length,
                    randomIndex,
                    temp;
                while (length) {
                    randomIndex = Math.floor(Math.random() * (length--));
                    temp = arr[randomIndex];
                    arr[randomIndex] = arr[length];
                    arr[length] = temp
                }
                return arr;
            },
            //处理奖品设置数据
            handSetprize(data) {
                console.log(this.clientData)
                // data = this.shuffle(data)
                this.luckDraw.prizes = []
                switch (this.clientData.lottery.type) {
                    case 'roulette':
                        data.forEach((item, index) => {
                            let prizeNews = {
                                name: "",
                                background: "#fff",
                                fonts: [
                                    {
                                        text: "",
                                        fontSize: '13px',
                                        top: -17
                                    }
                                ],
                                imgs: [{
                                    src: '', // 转盘底图
                                    width: "30px",
                                    top: 10,
                                    rotate: true
                                }]
                            }
                            prizeNews.name = item.name
                            prizeNews.fonts[0].text = item.name
                            prizeNews.imgs[0].src = item.image
                            this.luckDraw.prizes.push(prizeNews)
                        })
                        break
                    case 'rotate':
                        data.forEach((item, index) => {
                            let prizeNews = {
                                name: "",
                                background: this.styleSetting.prizes_bgColor,
                                borderRadius: '10px',
                                fonts: [
                                    {
                                        text: "",
                                        fontColor:  this.styleSetting.prizes_fontColor,
                                        fontSize: '10px',
                                        top: '78%'
                                    }
                                ],
                                imgs: [{
                                    src: '', // 转盘底图
                                    width: "70%",
                                    top: 5,
                                }]
                            }
                            // { x: 0, y: 0, fonts: [] }
                            prizeNews.name = item.name
                            prizeNews.fonts[0].text = item.name
                            prizeNews.imgs[0].src = item.image
                            this.luckyGrid.prizes[index] = {
                                ...this.luckyGrid.prizes[index],
                                ...prizeNews
                            }
                            // this.luckDraw.prizes.push(prizeNews)
                        })
                        break
                }
               
                
            },
            //  开始抽奖
            startCallBack() {
                contactLotteryApi({
                    id: this.id,
                    union_id: this.weChatUserNews.unionid,
                    nickname: this.weChatUserNews.nickname
                }).then((res) => {
                    if (!res.data.status) {
                        let prizeInfo={}
                        //在哪里停止
                        this.prizeSetNews.forEach((item, index) => {
                            if (item.id == res.data.prize_id) {
                            prizeInfo=item
                            this.$refs.LuckyWheel.play()
                                setTimeout(() => {
                                    // 索引值
                                    this.$refs.LuckyWheel.stop(index)
                                }, 3000)
                            }
                        })
                        //弹窗提醒
                        setTimeout(() => {
                            let prizeData = {
                                receiveName: res.data.prize_name,
                                receiveCode: res.data.receive_code,
                                receiveQr:res.data.receive_qr,
                                description: res.data.description,
                                type: res.data.receive_type,
                                nickname: this.weChatUserNews.nickname,
                                headimgurl: this.weChatUserNews.headimgurl,
                                id: res.data.lottery_record_id
                            }
                            if(prizeInfo.type==2){
                            prizeData.receiveQr=prizeInfo.image
                            }
                            this.$refs.prize.show(prizeData)
                            this.getClientData()
                        }, 6000)
                    } else {
                        this.$refs.prize.showErr(res.data)
                    }
                    
                })
            }
        }
    }
</script>

<style scoped lang="less">
    .page {
        width: 100vw;
        height: 100vh;
        // background-color: #ff5636;
        display: flex;
        flex-direction: column;
        // background-image: url("../../static/images/bg.png");
        background-size: cover;
        position: relative;
        overflow-y: auto;
        padding-bottom: 20px;
        padding-bottom: calc(20px + constant(safe-area-inset-bottom));//兼容 IOS<11.2
        padding-bottom: calc(20px + env(safe-area-inset-bottom));//兼容 IOS>11.2

        .title-box {
            background: linear-gradient(#f7c080, #f8bf94);
            // display: flex;
            // align-items: center;
            // justify-content: center;
            width: 100%;
            // min-height: 10vh;

            .logo {
                margin: 0px 8px;
                img {
                    border-radius: 4px;
                }
            }

            .company-name {
                .title {
                    margin-top: 4px;
                    font-size: 16px;
                    color: #ca4a4a;
                    font-weight: bold;
                }

                .synopsis {
                    font-weight: bold;
                    font-size: 14px;
                    color: rgba(#ca3d46, 0.6);
                }
            }

        }

        .title-content {
            display: flex;
            box-sizing: border-box;
            align-items: center;
            // justify-content: center;
            // width: 340px;
            padding: 4px 0;
            background-color: #fab34b;
            border: 2px solid #f59400 ;
            // border-radius: 5px;
        }

        .explain {
            position: absolute;
            padding: 2px 10px;
            right: 0;
            top: 80px;
            color: #b73551;
            background-color: #ffd4b8;
            border-radius: 4px 0 0 4px;
            box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.1);
            z-index: 13;
        }

        .prize_name {
            width: 80vw;
            height: 28px;
            margin-bottom: 18px;
            border-radius: 27px;
            background: #DD6D17;
            line-height: 27px;
            color: #fff;
            padding-left: 12px;
            font-size: 12px;
            display: flex;
            align-items: center;

            img {
                width: 18px;
                height: 18px;
                border-radius: 50%;
                margin-right: 8px;
            }

            span {
                font-weight: bold;
                margin-left: 2px;
                margin-right: 2px;
            }
        }

        .lottery-draw {
            // min-height: calc(100vh);
            position: relative;
            // display: flex;
            // justify-content: center;
            background-repeat: no-repeat;
            background-position: top center;
            background-size: 100%;

            .lottery-box {
                position: absolute;
                width: 100%;
                // height: 430px;
                // background: linear-gradient(#fff4a4, #faae7c);
                // border-radius: 18px;
                // margin-top: 26px;
                // margin-bottom: 26px;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;

                .top {
                    background-color: #f8bf65;
                    width: 78vw;
                    height: 45px;
                    margin: 16px 0;
                    border-radius: 4px;
                    display: flex;
                    box-shadow: 0 0 5px #D19275 inset;
                    align-items: center;
                    font-weight: bold;
                    font-size: 18px;
                    color: maroon;

                    .logo {
                        margin: 0 8px;
                        img {
                            border-radius: 4px;
                        }
                    }
                }

                .bottom {
                    width: 78vw;
                    height: 300px;
                    margin-bottom: 18px;
                    display: block;
                    position: relative;
                    z-index: 10;
                    background: linear-gradient(to top, #ffd38f, #f48744);
                    border-radius: 18px;

                    .box {
                      width: 78vw;
                    //   height: 275px;
                        height: 300px;
                      background-color: #fff;
                      border-radius: 18px;
                    //   position: absolute;
                    //   top: 50%;
                    //   left: 50%;
                    //   transform: translate(-50%, -50%);
                    //   padding: 8px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                }
            }


        }

        .record {
            display: flex;
            justify-content: center;

            .lottery-box {
                width: 90vw;
                padding-bottom: 20px;
                // height: 430px;
                // background: linear-gradient(#fff4a4, #faae7c);
                background: #f9f9f9;
                border: 1px solid #f5f5f5;
                border-radius: 10px;
                margin-top: 26px;
                // display: flex;
                // justify-content: center;
                // flex-wrap: wrap;

                .top {
                    text-align: center;
                    width: 88vw;
                    // height: 45px;
                    margin-top: 12px;
                    margin-bottom: 10px;

                    p {
                        color: #e3453a;
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 0;
                    }

                    span {
                        display: block;
                        margin-top: -16px;
                    }
                }

                .bottom {
                    width: 90vw;
                    max-height: 320px;
                    display: block;
                    position: relative;
                    z-index: 10;
                    // background: linear-gradient(to top, #f48744, #ffd38f);
                    border-radius: 18px;

                    .box {
                        width: 88vw;
                        max-height: 320px;
                        // background-color: #fff;
                        border-radius: 10px;
                        margin: 0 auto;
                        // position: absolute;
                        // top: 50%;
                        // left: 50%;
                        // transform: translate(-50%, -50%);
                        padding: 12px 16px 10px 16px;
                        overflow-y: scroll;
                    }

                    .no_draw_list {
                        text-align: center;
                        padding: 30px 0;
                    }
                }
            }
        }
    }

    .draw_list {
        margin-top: 20px;
    }

    .draw_list:first-child {
        margin-top: 0;
    }

    .converted {
        width: 66px;
        text-align: center;
        background: #F5F5F5;
        color: #B8B8B8;
        border: 1px solid #D9D9D9;
    }

    .exchange-box {
        display: flex;
        padding: 10px;
        background-color: #fff;
        border-radius: 10px;
        margin-top: 3px;
        align-items: center;

        .exchange-name {
            flex: 1;

            span {
                margin-left: 6px;
            }
        }
    }
</style>
